// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap/dist/css/bootstrap.css"
import "datatables/media/css/jquery.dataTables.css"
import "datatables.net-datetime/css/dataTables.dateTime.scss"
import 'select2/dist/css/select2.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import $ from 'jquery';
// export for others scripts to use
window.$ = $;

import "bootstrap"
import "popper.js"
import '@fortawesome/fontawesome-free/js/all';
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import Swal from 'sweetalert2'
window.Swal = Swal;

import moment from "moment";
window.moment = moment;

import '../table2excel/table2excel';

import "daterangepicker"

import Chart from "chart.js/auto"
window.Chart = Chart;

import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);




import 'select2'

import '@nathanvda/cocoon'

import "datatables"
import 'datatables.net-fixedcolumns'
import "datatables.net-colreorder"
import "datatables.net-datetime"

import "datatables.net-buttons"
import "datatables.net-buttons/js/buttons.colVis"
import "datatables.net-buttons/js/buttons.flash"
import "datatables.net-buttons/js/buttons.html5"
import "datatables.net-buttons/js/buttons.print"
import "datatable/lang.es.js";







